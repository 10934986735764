import React, { useEffect, useState } from "react";
import * as moment from "moment";
import { Radio } from "@material-ui/core";
import {
  AmountButtons,
  TwoButtonWrapper,
  Disclaimer,
  BigShareButton,
  BigDonateButton,
  ProgressBar,
  DonFooter,
  StatsBar,
  DonateScreen,
  CompleteButton,
  Message,
  DonorsBox,
  UserAvatar,
  StyledFallbackPayment
} from "./StoryStyles";
import footerlogo from "../assets/footerlogo.png";
import axios from "axios";
const humanNumber = require("human-number");
import Modal from "./Modal";
import creditcardsimg from "../assets/creditcards.png";
import { pause } from "video-react/lib/actions/player";
export const DonateScreenPage = ({
  purchases,
  dbgoal = 0,
  closefn,
  page_link,
  isOpen,
  shares,
  sharefn,
  apiHost,
  progressfn,
  donationpageid,
  onSuccess,
  togglePause,
  setGameId,
  setisFinalScreen,
  fullCamp,
  updateFromDB,
  setisGame,
  setIsDonate,
  avatar
}) => {
  let [userealname, setuserealname] = useState({
    value: true,
    wasset: false,
    show: false
  });

  let [upsellModal, setupsellModal] = useState({
    isOpen: false,
    size: "",
    numhats: 0
  });

  let [payreq, setPaymentRequest] = useState(false);
  let [isShowRecieptModal, setisShowRecieptModal] = useState(false);
  let [progress, setProgress] = useState(0);
  let [isFallBackShow, setisFallBackShow] = useState(false);
  let [goal, setGoal] = useState(dbgoal);
  let [paymentInfo, setPaymentInfo] = useState({
    amount: undefined
  });

  const initPayment = cb => {
    let networks = [
      "amex",
      "diners",
      "discover",
      "mastercard",
      "unionpay",
      "visa",
      "mir"
    ];
    let types = ["debit", "credit", "prepaid"];
    let supportedInstruments = [
      {
        supportedMethods: "basic-card",
        data: { supportedNetworks: networks, supportedTypes: types }
      }
    ];

    let details = {
      total: {
        label: "Donation/Purchase",
        amount: {
          currency: "USD",
          value: _.get(paymentInfo, "amount", 0)
        }
      },
      displayItems: [
        {
          label: "Original amount",
          amount: {
            currency: "USD",
            value: _.get(paymentInfo, "amount", 0)
          }
        }
      ]
    };
    let options = {
      requestPayerName: true,
      requestPayerPhone: true,
      requestPayerEmail: true
    };
    if (window.PaymentRequest) {
      let pr = new PaymentRequest(supportedInstruments, details, options);
      cb(pr);
    }
  };

  const handlePayment = e => {
    e.preventDefault(); //FIX THIS
    togglePause();
    if (payreq && false) {
      payreq
        .canMakePayment()
        .then(r => {
          if (!r) {
            setisFallBackShow(true);
            return Promise.reject("Old");
          }
        })
        .then(() => {
          return payreq.show();
        })
        .then(paymentResponse => {
          console.log("paymentResponse", paymentResponse);
          return axios
            .post(`${apiHost}/purchase/donate`, {
              amount: _.get(paymentInfo, "amount", 0),
              email: paymentResponse.payerEmail,
              donor_name: paymentResponse.payerName,
              cardholder_name: paymentResponse.details.cardholderName,
              card_number: paymentResponse.details.cardNumber,
              exp_date:
                paymentResponse.details.expiryMonth +
                "/" +
                new Date(paymentResponse.details.expiryYear)
                  .getFullYear()
                  .toString()
                  .slice(-2),
              cvv: paymentResponse.details.cardSecurityCode,
              donationpage: {
                id: donationpageid
              }
            })
            .then(result => {
              let error = result.data.error;
              if (error) {
                return Promise.reject(error);
              }
              let paymentId = result.data.Payload.insertId;
              setGameId(paymentId);
              paymentResponse.complete("success").then(function() {
                initPayment(setPaymentRequest);
                console.log(paymentResponse);
                setisGame(true);
                setIsDonate(false);
                updateFromDB();
              });
            });
        })
        .catch(e => {
          payreq.abort();
          initPayment(setPaymentRequest);
        });
    } else {
      // alert("not a secure site? fall back to normal");
      if (fullCamp.is_merch_checkout) {
        setupsellModal({
          ...upsellModal,
          isOpen: true
        });
      } else {
        setuserealname({
          ...userealname,
          show: true
        });
      }
    }
  };
  const returnSuffix = _.memoize(index => {
    let array = ["gave", "donated", "contributed"];
    return array[index % 3];
  });
  let donorBox = React.useRef();
  useEffect(() => {
    var elem = donorBox.current;
    var pos = 0;
    let con = 37.5;
    let stopThing = con * -6;
    let id;
    if (elem.childNodes.length > 6) id = setInterval(frame, 1000 * 3.5);
    function frame() {
      if (pos < stopThing) {
        pos = pos + con + 2.5;
        stopThing = 0;
      } else {
        stopThing = con * -6;
        pos = pos - con - 2.5;
      }
      elem.style.transform = `translateY(${pos}px)`;
    }
    return () => {
      clearInterval(id);
    };
  }, []);
  useEffect(() => {
    progressfn().then(r => {
      setGoal(r.goal);
      setProgress(r.progress);
    });
  }, [payreq]);
  useEffect(() => {
    if (_.get(paymentInfo, "amount") > 0) initPayment(setPaymentRequest);
    updateFromDB();
  }, [paymentInfo]);
  return (
    <DonateScreen
      bgColorOverride={"#" + fullCamp.color_a}
      bgColorOverride2={"#" + fullCamp.color_b}
      isOpen={isOpen}
    >
      <Modal
        isopen={userealname.show}
        quote={`“Awesome! Let me ask you…”`}
        buttons={
          <>
            <div
              style={{
                display: "grid",
                fontFamily: "HelveticaNeue-Medium",
                fontStyle: "italic",
                gridTemplateColumns: "35px 1fr 35px 1fr",
                alignItems: "center",
                fontSize: "1.2rem",
                paddingBottom: "0rem",
                color: "#4A4A4A",
                padding: "0rem 2.5rem",
                width: "100%",
                alignSelf: "center",
                marginTop: "2rem"
              }}
            >
              <Radio
                disableRipple
                checked={userealname.value}
                onChange={() => {
                  setuserealname({
                    ...userealname,
                    value: true
                  });
                }}
                value="true"
                name="radio-button-demo"
              />
              <label>Use my name</label>
              <Radio
                disableRipple
                checked={!userealname.value}
                onChange={() => {
                  setuserealname({
                    ...userealname,
                    value: false
                  });
                }}
                value="false"
                name="radio-button-demo"
                inputProps={{ "aria-label": "A" }}
              />
              <label>Use 'Anonymous'</label>
            </div>
            <CompleteButton
              onClick={() => {
                setuserealname({
                  ...userealname,
                  wasset: true,
                  show: false
                });
                setisFallBackShow(true);
              }}
              className="pink"
            >
              Next
            </CompleteButton>
          </>
        }
        message={
          <>
            <Message>
              Can we thank you publicly, or would you like to remain an
              anonymous donor?
            </Message>
          </>
        }
        avatar={<UserAvatar alt="Profile Pic" src={avatar} />}
      />

      <Modal
        isopen={isShowRecieptModal}
        quote={`“Payment successful. Thank you!”`}
        buttons={
          <>
            <div
              style={{
                display: "grid",
                fontFamily: "HelveticaNeue-Medium",
                fontStyle: "italic",
                gridTemplateColumns: "95px 1fr",
                alignItems: "center",
                fontSize: "1.2rem",
                paddingBottom: "0rem",
                color: "#4A4A4A",
                padding: "0rem 2.5rem",
                width: "100%",
                alignSelf: "center",
                marginTop: "2rem"
              }}
            >
              <img width="85px" src={creditcardsimg} alt="credit cards" />
              <span
                style={{
                  width: "200px",
                  height: "40px",
                  fontFamily: "Helvetica Neue",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "14px",
                  lineHeight: "18px",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  letterSpacing: "0.104975px",
                  color: "#4A4A4A"
                }}
              >
                Your payment was successfull. check your email for details.
              </span>
            </div>
            <CompleteButton
              onClick={() => {
                setisShowRecieptModal(false);
                setisFinalScreen(true);
              }}
              className="pink"
            >
              Next
            </CompleteButton>
          </>
        }
        message={<></>}
        avatar={<UserAvatar alt="Profile Pic" src={avatar} />}
      />
      <Modal
        isopen={upsellModal.isOpen}
        quote={`“${_.get(fullCamp, "merch_upsell_text", "Wear it Proud!")}”`}
        buttons={
          <>
            <div
              style={{
                display: "grid",
                fontFamily: "HelveticaNeue-Medium",
                fontStyle: "normal",
                gridTemplateColumns: "1fr",
                alignItems: "center",
                fontSize: "1.2rem",
                paddingBottom: "0rem",
                color: "#4A4A4A",
                padding: "0rem 2.5rem",
                alignSelf: "center",
                marginTop: "0rem"
              }}
            >
              <div className="spread">
                <span>Select shirt size:</span>
                <span
                  onClick={() => {
                    setuserealname({
                      wasset: false
                    });
                    setupsellModal({
                      isOpen: false
                    });
                  }}
                >
                  GO BACK
                </span>
              </div>
              <div className="sizeHolder">
                {["S", "M", "L", "XL", "2XL"].map((x, i) => {
                  return (
                    <div
                      className={
                        upsellModal.size == x ? "active button" : "button"
                      }
                      onClick={e => {
                        setupsellModal({
                          ...upsellModal,
                          size: x
                        });
                      }}
                      key={i}
                    >
                      {x}
                    </div>
                  );
                })}
              </div>
              <div className="upsellmessage">
                The MIGA hats remind us that integrity is crucial to the
                strength of our democracy. Add a few to give away…
              </div>
              <div className="spread gutterTop">
                <span className="item">
                  Additional Hats: <strong>$20</strong>
                </span>
              </div>
              <div className="sizeHolder buttons">
                <span className="chooser">
                  <div
                    className="big"
                    style={{
                      letterSpacing: "-5px"
                    }}
                    onClick={() => {
                      setupsellModal({
                        ...upsellModal,
                        numhats: Math.max(
                          parseInt(upsellModal.numhats, 10) - 1,
                          0
                        )
                      });
                    }}
                  >
                    ----
                  </div>
                  <div>{upsellModal.numhats}</div>
                  <div
                    className="big"
                    onClick={() => {
                      setupsellModal({
                        ...upsellModal,
                        numhats: parseInt(upsellModal.numhats, 10) + 1
                      });
                    }}
                  >
                    +
                  </div>
                </span>
                <span className="chooser total">
                  <div>Total</div>
                  <div
                    style={{
                      border: 0,
                      width: "98%",
                      height: "99%",
                      borderLeft: "0.5px solid #979797"
                    }}
                  >
                    $
                    {_.get(paymentInfo, "amount", 0) / 1 +
                      parseInt(upsellModal.numhats, 10) * 20}
                  </div>
                </span>
              </div>
            </div>
            <CompleteButton
              disabled={upsellModal.size == ""}
              onClick={() => {
                setupsellModal({
                  ...upsellModal,
                  isOpen: false
                });
                setuserealname({
                  show: true
                });
                setPaymentInfo({
                  ...paymentInfo,
                  amount:
                    _.get(paymentInfo, "amount", 0) / 1 +
                    parseInt(upsellModal.numhats, 10) * 20
                });
              }}
              className="pink"
            >
              Next
            </CompleteButton>
          </>
        }
        message={<></>}
        avatar={<UserAvatar alt="Profile Pic" src={avatar} />}
      />

      <StyledFallbackPayment
        open={isFallBackShow}
        handleClose={() => {
          setisFallBackShow(false);
          setisShowRecieptModal(false);
        }}
        fullCamp={fullCamp}
        userealname={userealname.value}
        onSuccess={onSuccess}
        setGameId={setGameId}
        hasGame={fullCamp.is_show_game}
        getAddress={fullCamp.is_merch_checkout}
        setisGame={setisGame}
        page_link={page_link}
        setisShowRecieptModal={setisShowRecieptModal}
        setisFinalScreen={setisFinalScreen}
        setIsDonate={setIsDonate}
        updateFromDB={updateFromDB}
        amount={_.get(paymentInfo, "amount")}
        apiHost={apiHost}
        donationpageid={donationpageid}
      />
      {/* <span onClick={closefn}>
        <span className="close">
          <i className="icon ion-ios-close" />
        </span>
      </span> */}
      <div>
        <ProgressBar
          style={{
            display: fullCamp.is_merch_checkout ? "none" : "block"
          }}
          position={"relative"}
          bottomspace={"1rem"}
          shadow={"none"}
          color={"#4A4A4A"}
          top={"0rem"}
          show={!fullCamp.is_merch_checkout}
          margin={"1rem 0  0 0"}
          maxwidth={"calc(100% - 2rem)"}
        >
          <span>
            <em>
              {progress.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}{" "}
            </em>
            raised of{" "}
            {goal.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}{" "}
            goal
          </span>
          <div>
            <div
              className="progress-child"
              style={{
                minWidth: "15px",
                width: (progress / goal) * 100 + "%"
              }}
            />
          </div>
        </ProgressBar>
        <StatsBar>
          <div>
            {_.size(purchases)}{" "}
            <span> {fullCamp.is_merch_checkout ? "advocates" : "donors"}</span>
          </div>
          <div>
            {shares} <span>Shares</span>
          </div>
          {/* <div>
                        1 <span>Comments</span>
                      </div> */}
        </StatsBar>
        <form onSubmit={handlePayment}>
          <AmountButtons
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setPaymentInfo({
                ...paymentInfo,
                wasset: true,
                amount: _.get(e, "target.dataset.value", paymentInfo.amount)
              });
            }}
          >
            {!fullCamp.is_merch_checkout && (
              <>
                <button data-value="1000">$1,000</button>
                <button data-value="500">$500</button>
                <button data-value="300">$300</button>
                <button data-value="200">$200</button>
                <button data-value="100">$100</button>
                <button data-value="50">$50</button>
                <button data-value="35">$35</button>
                <div className="special">
                  <span>$20.00 Minimum Donation </span>
                  <input
                    type="number"
                    min={20}
                    required
                    onChange={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      // console.log(e.target.value);
                      setPaymentInfo({
                        ...paymentInfo,
                        amount: _.get(e, "target.value", paymentInfo.amount)
                      });
                    }}
                    value={paymentInfo.amount}
                    placeholder="$ Other Amount"
                  />
                </div>
              </>
            )}
            {fullCamp.is_merch_checkout &&
              _.sortBy(fullCamp.storeitems, "value").map((x, o) => {
                let style = {};
                if (o == fullCamp.storeitems.length - 1) {
                  style = {
                    gridRow: "3/span 4",
                    gridColumn: "1/ span 3"
                  };
                }
                return (
                  <img
                    key={x.id || o}
                    style={style}
                    className={
                      paymentInfo.amount == x.value
                        ? "active"
                        : paymentInfo.wasset
                        ? "inactive"
                        : ""
                    }
                    data-value={x.value}
                    src={x.button_image.url}
                  />
                );
              })}
          </AmountButtons>
          <Disclaimer>
            By continuing, you agree that you are 18 years of age or older, and
            to our{" "}
            <a href="https://www.mygivhub.org/terms/" target="_blank">
              Terms of Use
            </a>{" "}
            and{" "}
            <a href="https://www.mygivhub.org/privacy/" target="_blank">
              Privacy Policy
            </a>{" "}
          </Disclaimer>
          <TwoButtonWrapper>
            <BigShareButton
              onClick={async e => {
                e.preventDefault();
                axios.get(apiHost + "/shared/donation-page/" + donationpageid);
                sharefn();
              }}
            >
              SHARE CAMPAIGN
            </BigShareButton>
            <BigDonateButton
              style={{
                background: `#${fullCamp.color_b}`
              }}
              disabled={!paymentInfo.wasset}
              type="submit"
            >
              {fullCamp.is_merch_checkout ? "Place Order" : "Make Donation"}
            </BigDonateButton>
          </TwoButtonWrapper>
        </form>
      </div>
      <DonorsBox>
        <span
          style={{
            color: `#${fullCamp.color_a}`
          }}
        >
          A huge thanks to our supporters!
        </span>
        <ul ref={donorBox}>
          {_.sortBy(purchases, "id")
            .reverse()
            .map((purchase, i) => {
              return (
                <li
                  className={(purchase => {
                    switch (true) {
                      case purchase.amount / 100 <= 30:
                        return "clap";
                        break;
                      case purchase.amount / 100 <= 50:
                        return "angel";
                      case purchase.amount / 100 <= 100:
                        return "wow";
                        break;
                      default:
                        return "cool";
                        break;
                    }
                  })(purchase)}
                  index={i}
                  key={purchase.id}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        _.startCase(_.toLower(purchase.donor_name)) +
                        " " +
                        returnSuffix(i) +
                        "..."
                    }}
                  />
                  <div>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: "$" + humanNumber(purchase.amount / 100)
                      }}
                    />
                    <span
                      dangerouslySetInnerHTML={{
                        __html: moment(
                          _.get(purchase, "purchase_date") ||
                            _.get(purchase, "created_date")
                        ).fromNow()
                      }}
                    />
                  </div>
                </li>
              );
            })}
        </ul>
      </DonorsBox>
      {/* <DonFooter>
        <img src={footerlogo} alt="GH logo" />
        <p className="white">Smarter giving. Smarter fundraising.</p>
      </DonFooter> */}
    </DonateScreen>
  );
};
