import React, { useState } from "react";
import {
  StepsGrid,
  CardLink,
  StyledModal,
  StyledPlayer,
  BigPlayButton
} from "../styles/shell-styles";

import { textAlign, minHeight } from "@material-ui/system";
export default ({
  title = "Howdy Hubraiser!",
  copy = `Welcome to your dashboard home page! From here, you can add organizations, invite others to fundraise for causes they care about, claim your Hubraiser rewards and more! Follow these steps to unlock your fundraising potential.`,
  stepsArray = [
    {
      title: "Set Up Your Profile",
      videolink:
        "https://givhub-production.s3.us-east-2.amazonaws.com/videos/settingupprofile-min.mp4",
      link: "/dashboard/hub/user/settings",
      linktext: "Go to Settings"
    },
    {
      title: "Add an Organization",
      videolink:
        "https://givhub-production.s3.us-east-2.amazonaws.com/videos/searchorg-min.mp4",
      link: "/dashboard/hub/add-org",
      linktext: "Add Organization"
    },
    {
      title: "Create Donation Page",
      videolink:
        "https://givhub-production.s3.us-east-2.amazonaws.com/ca17209f6ff14e27bacdef4757764d34.mp4",
      link: "/dashboard/active-org/donation-page/",
      linktext: "CREATE DONATION PAGE"
    },
    {
      title: "Invite Donors to Give",
      videolink: "",
      link: "/dashboard/active-org/add-donors/",
      linktext: "INVITE DONORS"
    }
  ],
  logo
}) => {
  const [isOpen, setOpen] = useState(false);
  const [player, setPlayer] = useState(false);
  const [IsPlaying, setIsPlaying] = useState(false);
  const [video, setVideo] = useState(false);
  return (
    <>
      {/* <h3>News and Reviews</h3> */}
      <div>
        {logo ? (
          <div
            style={{
              display: "grid",
              margin: "0 0 2rem 0",
              gridTemplateColumns: "1fr 5rem",
              gridGap: "1rem"
            }}
          >
            <h4
              style={{
                maxWidth: "100%",
                margin: "0",
                alignSelf: "left"
              }}
              dangerouslySetInnerHTML={{
                __html: title
              }}
            />
            <img
              style={{
                maxWidth: "100%",
                margin: "0 auto",
                alignSelf: "center"
              }}
              src={logo}
              alt="logo"
            />
          </div>
        ) : (
          <h4
            style={{
              maxWidth: "100%",
              alignSelf: "left"
            }}
            dangerouslySetInnerHTML={{
              __html: title
            }}
          />
        )}

        <p
          dangerouslySetInnerHTML={{
            __html: copy
          }}
        />
      </div>
      <StepsGrid>
        <ol>
          {stepsArray.map((s, i) => {
            return (
              <li key={i}>
                <div>
                  <h4>{s.title}</h4>
                  <div>
                    <CardLink
                      className={s.videolink === "" ? "disabled" : ""}
                      onClick={e => {
                        e.preventDefault();
                        if (s.videolink !== "") {
                          setVideo(s.videolink);
                          setOpen(true);
                        }
                      }}
                    >
                      Watch Video
                    </CardLink>
                    <CardLink to={_.get(s, "link", "")}>{s.linktext}</CardLink>
                  </div>
                </div>
              </li>
            );
          })}
        </ol>
      </StepsGrid>
      <StyledModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        <div className="stage">
          <StyledPlayer
            ref={s => setPlayer(s)}
            autoPlay
            onEnded={() => {
              setIsPlaying(false);
              setOpen(false);
            }}
            onPlay={e => {
              setIsPlaying(true);
            }}
            preload="auto"
            crossOrigin="anonymous"
          >
            <BigPlayButton position="center" />
            <source
              style={{ outline: "0" }}
              crossOrigin="anonymous"
              src={video + "?no-cache=1"}
            />
          </StyledPlayer>
        </div>
      </StyledModal>
    </>
  );
};
