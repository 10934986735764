import React from "react";
import styled from "styled-components";
import { Button, Popper, Fade, Backdrop } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Info from "../../../../static/styles/icons/info";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";

const StyledButton = styled(Button)`
  height: 35px;
  width: ${props => props.width || "80px"};
  color: #fff;
  background-color: #e51670 !important;
  font-family: "HelveticaNeue";
  grid-column: 2;
  grid-row: auto;
  justify-self: end;
  font-size: 1.4rem;
  font-weight: 900;
  line-height: 14px;
  text-transform: uppercase;
  box-shadow: none;
  font-weight: bold;
  height: auto;
  min-width: 150px;
  min-height: 38px;
  align-self: flex-end;
`;

const InfoIcon = styled(Info)`
  margin: 0 0 -0.7rem 0.4rem;
  width: 20px;
  height: 20px;
  fill: #4a4a4a;
`;
const CardImg = styled.img`
  max-width: 40px;
  margin-right: 0.6rem;
`;

const Section = styled.section`
  display: grid;
  grid-gap: 10px;
  width: 100%;
  filter: ${({ dimmed }) => (dimmed ? "brightness(.5)" : "none")};
`;
const SectionText = styled.p`
  margin-top: ${props => props.margintop};
  grid-column: 1 / span 2;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;

  color: #4a4a4a;
`;
const ShowFormBtn = styled.p`
  margin: ${props => props.margin};
  letter-spacing: 0.56px;
  color: #1264a3 !important;
`;
const SectionTextWithBorder = styled(SectionText)`
  margin: ${props => props.margin || "0"};
  padding-bottom: 0.6rem;
  border-bottom: 1px solid #999;
`;
const StyledLabel = styled.label`
  font-family: HelveticaNeue-Bold;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.56px;
  color: #4a4a4a;
  img {
    margin-right: 2rem;
  }
`;

const EditCampaignExpansion = styled(ExpansionPanelDetails)`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1rem;
  width: 100%;
  @media (max-width: 500px) {
    grid-template-rows: min-content min-content;
  }
  label {
    font-family: HelveticaNeue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.171429px;
    color: #4a4a4a;
    margin-right: 1rem;
  }

  div.dates {
    display: grid;
    grid-template-columns: max-content 1fr max-content 1fr;
    align-items: center;
    grid-gap: 1rem;
    .react-datepicker__month-container {
      float: none;
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-size: 1.4rem !important;
    }
    .react-datepicker__day-names {
      width: 100%;

      .react-datepicker__day-name {
        font-size: 1.2rem;
        width: 13%;
      }
    }

    .react-datepicker__day,
    .react-datepicker__time-name,
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      place-content: center center;
      display: inline-grid;
      font-size: 1.2rem;
      min-width: 25px;
      min-height: 25px;
    }
    @media (max-width: 535px) {
      .react-datepicker-popper {
        // transform: none !important;
      }
      grid-template-columns: max-content 1fr;
      grid-template-rows: 50px 30px;
      .react-datepicker__day {
        min-width: 8.5vw;
        min-height: 8.5vw;
        font-size: 1.4rem;
      }
    }

    input {
      padding: 0.5rem;
      border: 1px solid #ddd;
      font-weight: bold;
      font-size: 1.4rem;
      max-width: 100px;
    }
  }
  div.radiowrapper {
    // min-width: 100%;
    max-width: calc(100%)-2rem;
    display: grid;
    grid-template-columns: repeat(3, 33%);

    @media (max-width: 535px) {
      grid-template-columns: 1fr;
    }
    grid-gap: 1rem;
    span {
      max-width: 100%;
      display: flex;
      align-items: center;
      label {
        margin: 0;
      }
      ${InfoIcon} {
        margin: 0;
        margin-left: 1rem;
      }
    }
    .MuiSvgIcon-root {
      font-size: 2rem;
    }
    .MuiTypography-body1 {
      font-family: HelveticaNeue;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.171429px;
      color: #4a4a4a;
    }
  }
`;
export class CampSettings extends React.Component {
  constructor(props) {
    super(props);
    props = props.props;
    console.log(props);
    let orgs = _.get(props, "state.orgs", [])
      .slice(1)
      .filter(org => !org.fullresult.isdeleted && !org.fullresult.ishidden);
    let startArray = _.reduce(
      orgs,
      (p, n) => {
        p[n.fullresult.id] = n.fullresult.start_date;
        return p;
      },
      {}
    );
    let endArray = _.reduce(
      orgs,
      (p, n) => {
        p[n.fullresult.id] = n.fullresult.end_date;
        return p;
      },
      {}
    );
    let typeArray = _.reduce(
      orgs,
      (p, n) => {
        p[n.fullresult.id] = n.fullresult.runtime_type;
        return p;
      },
      {}
    );
    this.state = {
      typeArray,
      startArray,
      endArray,
      orgs: orgs,
      popperEl: null
    };
  }
  render() {
    return (
      <Section
        onClick={() => {
          if (!!this.state.popperEl)
            this.setState({
              popperEl: undefined
            });
        }}
        id="camp-settings-section"
      >
        <Popper
          placement="top"
          disablePortal={true}
          open={!!this.state.popperEl}
          anchorEl={this.state.popperEl}
          transition
          style={{
            zIndex: "10000"
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div
                onClick={() => {
                  this.setState({
                    popperEl: undefined
                  });
                }}
                style={{
                  padding: "2rem",
                  background: "white",
                  border: "1px solid #dedede",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  maxWidth: "300px",
                  boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.98245)",
                  borderRadius: "6px"
                }}
              >
                <p
                  style={{
                    fontFamily: "HelveticaNeue-Medium",
                    fontSize: "14px",
                    lineHeight: "20px",
                    letterSpacing: "0.2px",
                    color: "#062E60"
                  }}
                >
                  {this.state.poppertext}
                </p>
              </div>
            </Fade>
          )}
        </Popper>
        <SectionText>
          Here you can set or change your fundraising campaign settings for the
          organizations you support. GivHub offers 3 types of fundraising
          campaigns for HubRaisers to manage. In some instances, as is the case
          with National Youth Organizations, a campaign type is pre-selected.
          Additionally, Verified Hubraisers can manage and set the paramters
          (campaign type & dates) for an organization.
        </SectionText>
        <SectionTextWithBorder margin="0 1.8rem">
          Manage your organizations Campaign Settings below:
        </SectionTextWithBorder>

        {this.state.orgs.map((org, i) => (
          <div
            key={i}
            style={{
              gridColumn: "1/3"
            }}
          >
            <ExpansionPanel
              defaultExpanded={
                _.get(this.props.props, "activeMenu.activeOrg.id") == org.org.id
              }
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  <StyledLabel>
                    <CardImg src={org.campaign.logo.url} alt="visa" />
                    {org.org.name}
                  </StyledLabel>
                </Typography>
              </ExpansionPanelSummary>
              <EditCampaignExpansion>
                <>
                  <div className="radiowrapper">
                    <span>
                      <FormControlLabel
                        value="monthly"
                        control={<Radio color="primary" />}
                        label="Monthly Campaign"
                        labelPlacement="end"
                        onClick={e => {
                          let state = this.state.typeArray;
                          state[_.get(org, "donid")] = e.target.value;
                          let startState = this.state.startArray;
                          startState[_.get(org, "donid")] = moment().format(
                            "YYYY-MM-DD"
                          );
                          let endArray = this.state.endArray;
                          endArray[_.get(org, "donid")] = null;
                          this.setState({
                            startArray: startState,
                            typeArray: state,
                            endArray
                          });

                          let o = {
                            donationpageid: _.get(org, "donid"),
                            start_date: moment().format("YYYY-MM-DD"),
                            end_date: undefined,
                            runtime_type: e.target.value
                          };
                          this.props.props.OrgUpdate(o);
                          this.props.props.setStateFromDB();
                        }}
                        checked={
                          this.state.typeArray[_.get(org, "donid")] == "monthly"
                        }
                      />
                      <InfoIcon
                        onClick={e => {
                          if (_.isUndefined(this.state.popperEl))
                            this.setState({
                              popperEl: e.target,
                              poppertext: `Choose a Monthly Campaign when you intend on maintaining and regularly updating an ongoing fundraising campaign. Monthly Campaigns often include regular communication with your donors and allow for monthly reoccurring donations. You can suspend your Monthly Campaign at any time.`
                            });
                          else
                            this.setState({
                              popperEl: undefined
                            });
                        }}
                      />
                    </span>
                    <span>
                      <FormControlLabel
                        value="event"
                        onClick={e => {
                          let state = this.state.typeArray;
                          state[_.get(org, "donid")] = e.target.value;
                          let endArray = this.state.endArray;
                          endArray[_.get(org, "donid")] = null;
                          let startState = this.state.startArray;
                          startState[_.get(org, "donid")] = moment().format(
                            "YYYY-MM-DD"
                          );
                          this.setState({
                            typeArray: state,
                            endArray,
                            startState
                          });
                          let o = {
                            donationpageid: _.get(org, "donid"),
                            runtime_type: e.target.value
                          };
                          this.props.props.OrgUpdate(o);
                          this.props.props.setStateFromDB();
                        }}
                        control={<Radio color="primary" />}
                        label="Event Based Campaign"
                        labelPlacement="end"
                        checked={
                          this.state.typeArray[_.get(org, "donid")] == "event"
                        }
                      />
                      <InfoIcon
                        onClick={e => {
                          if (_.isUndefined(this.state.popperEl))
                            this.setState({
                              popperEl: e.target,
                              poppertext: `Choose an Event Based Campaign when you want to start fundraising for an organization immediately and continue fundraising through the date of a future event.`
                            });
                          else
                            this.setState({
                              popperEl: undefined
                            });
                        }}
                      />
                    </span>
                    <span>
                      <FormControlLabel
                        value="days28"
                        onClick={e => {
                          let state = this.state.typeArray;
                          state[_.get(org, "donid")] = e.target.value;
                          let endArray = this.state.endArray;
                          endArray[_.get(org, "donid")] = moment(
                            this.state.startArray[_.get(org, "donid", "today")]
                          )
                            .add(28, "days")
                            .format("YYYY-MM-DD");
                          this.setState({
                            typeArray: state
                          });
                          let o = {
                            donationpageid: _.get(org, "donid"),
                            runtime_type: e.target.value
                          };
                          this.props.props.OrgUpdate(o);
                          this.props.props.setStateFromDB();
                        }}
                        control={<Radio color="primary" />}
                        label="28 day Campaign"
                        labelPlacement="end"
                        checked={
                          this.state.typeArray[_.get(org, "donid")] == "days28"
                        }
                      />
                      <InfoIcon
                        onClick={e => {
                          if (_.isUndefined(this.state.popperEl))
                            this.setState({
                              popperEl: e.target,
                              poppertext: `A 28 Day Campaign is ideal for youth fundraising and required for campaigns where fundraisers compete for scholarships. Many Nationwide youth campaigns like team sports, school programs, and National Youth Organizations are locked as a 28 Day Campaign.`
                            });
                          else
                            this.setState({
                              popperEl: undefined
                            });
                        }}
                      />
                    </span>
                  </div>
                  <div className="dates">
                    <label>Campaign Start Date:</label>
                    <DatePicker
                      minDate={new Date()}
                      monthsShown={2}
                      placeholderText="Select a date"
                      // fixedHeight
                      shouldCloseOnSelect={false}
                      disabled={
                        this.state.typeArray[_.get(org, "donid")] == "event" ||
                        this.state.typeArray[_.get(org, "donid")] == "monthly"
                      }
                      selected={
                        moment(
                          this.state.startArray[_.get(org, "donid")]
                        ).isValid()
                          ? moment(
                              this.state.startArray[_.get(org, "donid")]
                            ).toDate()
                          : null
                      }
                      highlightDates={
                        moment(
                          this.state.startArray[_.get(org, "donid")]
                        ).isValid()
                          ? [
                              moment(
                                this.state.startArray[_.get(org, "donid")]
                              ).toDate(),
                              ..._.times(28, x => {
                                return moment(
                                  this.state.startArray[_.get(org, "donid")]
                                )
                                  .add(x, "days")
                                  .toDate();
                              })
                            ]
                          : []
                      }
                      onChange={startDate => {
                        let startArray = this.state.startArray;
                        startArray[_.get(org, "donid")] = moment(
                          startDate
                        ).format("YYYY-MM-DD");
                        let endArray = this.state.endArray;
                        if (
                          this.state.typeArray[_.get(org, "donid")] == "days28"
                        ) {
                          endArray[_.get(org, "donid")] = moment(startDate)
                            .add(28, "days")
                            .format("YYYY-MM-DD");
                        } else if (
                          this.state.typeArray[_.get(org, "donid")] == "event"
                        ) {
                          endArray[_.get(org, "donid")] = moment(
                            startDate
                          ).format("YYYY-MM-DD");
                        } else if (
                          this.state.typeArray[_.get(org, "donid")] == "monthly"
                        ) {
                          endArray[_.get(org, "donid")] = null;
                        }

                        this.setState({
                          startArray,
                          endArray
                        });
                        let o = {
                          donationpageid: _.get(org, "donid"),
                          start_date: moment(startDate).format("YYYY-MM-DD"),
                          end_date:
                            this.state.typeArray[_.get(org, "donid")] ==
                            "days28"
                              ? moment(startDate)
                                  .add(28, "days")
                                  .format("YYYY-MM-DD")
                              : null
                        };
                        this.props.props.OrgUpdate(o);
                        this.props.props.setStateFromDB();
                      }}
                    />
                    <label>
                      {this.state.typeArray[_.get(org, "donid")] == "event"
                        ? "Until Event Date:"
                        : this.state.typeArray[_.get(org, "donid")] !== "days28"
                        ? "Campaign won't end:"
                        : "for 28 days until:"}
                    </label>
                    <DatePicker
                      minDate={new Date()}
                      minDate={new Date()}
                      monthsShown={2}
                      // fixedHeight
                      shouldCloseOnSelect={false}
                      selected={
                        moment(
                          this.state.endArray[_.get(org, "donid")]
                        ).isValid() &&
                        this.state.typeArray[_.get(org, "donid")] !== "monthly"
                          ? moment(
                              this.state.endArray[_.get(org, "donid")]
                            ).toDate()
                          : null
                      }
                      placeholderText="--/--/--"
                      moveRangeOnFirstSelection={false}
                      onChange={endDate => {
                        let endArray = this.state.endArray;
                        endArray[_.get(org, "donid")] = moment(endDate).format(
                          "YYYY-MM-DD"
                        );
                        let o = {
                          donationpageid: _.get(org, "donid"),
                          start_date: moment().format("YYYY-MM-DD"),
                          end_date: moment(endDate).format("YYYY-MM-DD")
                        };
                        this.props.props.OrgUpdate(o);
                        this.setState({
                          endArray
                        });
                      }}
                      selected={
                        moment(
                          this.state.endArray[_.get(org, "donid")]
                        ).isValid()
                          ? moment(
                              this.state.endArray[_.get(org, "donid")]
                            ).toDate()
                          : null
                      }
                      //   endDate={moment(org.fullresult.end_date)}
                      disabled={
                        this.state.typeArray[_.get(org, "donid")] !== "event"
                      }
                    />
                  </div>
                </>
              </EditCampaignExpansion>
            </ExpansionPanel>
          </div>
        ))}

        <ShowFormBtn margin="0 1.1rem 2.7rem 1.8rem">
          Add a new organization
        </ShowFormBtn>
        <StyledButton
          style={{
            marginTop: "1rem"
          }}
          onClick={() => {
            this.props.props.closeEx();
          }}
          variant="contained"
        >
          Close
        </StyledButton>
      </Section>
    );
  }
}
